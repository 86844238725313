window.createModal = function(target, html, callback = function() {}){
	if($('#'+target).length){
		$('#'+target).remove();
	}
	$('#modal_container').append(html);
	$('.modal').modal('hide');
	$('#'+target).modal({ backdrop: "static" });
	$('#'+target).find('.select2:not(.custom)').select2({'width':'100%'});
	callback();
};

window.showModal = function(target){
	$('.modal').modal('hide');
	$('#'+target).modal({ backdrop: "static" });
};

window.hideModal = function(target){
	$('#'+target).modal('hide');
};

window.buildDynamicModal = function(target, data, callback = function(){}, custom_loader = false, pass_or_fail_callback = function(response){}){
	if(!custom_loader){
		showLoader();
	}
	var parsed_target = target.replace(/_/g, '-');
	makeRequest('/views/modal/'+parsed_target, data, function (response) {
		if(isset(response.success) && response.success && isset(response.html)) {
			target = target.replace(/-/g, '_');
			createModal(target, response.html, callback);
		}
		else{
			var error = 'unable to build modal';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}
		if(!custom_loader){
			hideLoader();
		}
		pass_or_fail_callback(response);
	});
};