$(document).ready(function() {
	
	$('#ticket_filters').on('change','#priority-selection',function(){
		var to_search = $(this).siblings('#search-tickets').val();
		var to_filter = $(this).val();	
		filterTicketQueueItems($(this), to_search, to_filter);		
	});
	
	$('#ticket_filters').on('keyup','#search-tickets',function(){
		var to_search = $(this).val();
		var to_filter = $(this).siblings('#priority-selection').val();	
		filterTicketQueueItems($(this), to_search, to_filter);		
	});


	if($('#ticket_actions').length){
		$('.todo-date').daterangepicker({
			locale: {
			format: 'DD/MM/YYYY h:mm A' },
			"singleDatePicker": true,
			"timePicker24Hour": true,
			"timePicker": true,
			"timePickerIncrement": 10,
			"autoApply": true,
			"showCustomRangeLabel": false	
	//		"startDate": "03/08/2017",
	//		"endDate": "02/23/2017"
		});
	}

	
	var filterTicketQueueItems = function(element, to_search, to_filter){
		var ticket_container = $(element).parent().siblings('#tickets-container');
		var minimum_val = 0;
		var priority_message = " with "+ $(element).siblings('#priority-selection').children(':selected').text()+" Priority";
		if($(element).attr('id') == 'priority-selection'){
			priority_message = " with "+ $(element).children(':selected').text()+" Priority";
		}
		var ticket_items = $(ticket_container).find('div.ticket-item');
		
		$(ticket_items).addClass('hide');
		$(ticket_container).find('.no-tickets-for-selected-priority-message').hide();

		if(to_search == '' && to_filter == 'all'){
			$(ticket_items).removeClass('hide');
		}
		else {
			if(to_search != ''){
				$(ticket_items).each(function(){
					var compare_search = $(this).find('h4').text().toLowerCase().indexOf(to_search.toLowerCase());
					var item_priority = $(this).data('priority');
					priority_message = " found for search: \""+to_search+"\"";
					
					if(to_filter == 'all'){
						priority_message = " found for search: \""+to_search+"\"";
					}
					
					if(to_filter != 20){
						minimum_val = to_filter - 19;
					}
					
					if(compare_search != -1 && to_filter == 'all'){	
						$(this).removeClass('hide');
					}
					else if(compare_search != -1 && item_priority >= minimum_val && item_priority <= to_filter) {
						if(to_filter != 20){
							minimum_val = to_filter - 19;
						}
						$(this).removeClass('hide');
					}
					else {
						$(this).addClass('hide')
					}
				});
			}
			else {
				if(to_filter == 'all'){
					priority_message = "";
				}

				if(to_filter != 20){
					minimum_val = to_filter - 19;
				}
				$(ticket_items).each(function(){
					($(this).data('priority') >= minimum_val && $(this).data('priority') <= to_filter)? $(this).removeClass('hide') : $(this).addClass('hide');
				});
			}

			if(ticket_container.children().length > 0){
				ticket_container.children().each(function(){
					if($(this).find('.ticket-item.hide').length == $(this).find('.ticket-item').length){
						$(this).find('.no-tickets-for-selected-priority-message').text("No Tickets"+priority_message);
						$(this).find('.no-tickets-for-selected-priority-message').show();
					}
					else {
						$(this).find('.no-tickets-for-selected-priority-message').hide();
					}
				});
			}
		}
		
	};
	
	
	if($('#check-ticket-item-content-might-be-html').length > 0) {
		var parent = $('.ticket-item-content');
		parent.children().each(function(){
			var tag = $(this).prop("tagName");
			if(tag.indexOf("@") != -1 ){
				var myCollection = document.getElementsByTagName(tag);
				var i;
				for (i = 0; i < myCollection.length; i++) {
					myCollection[i].style.whiteSpace = "pre-line";
				}
			}
		});
	}

	var getTicketsForQueue = function(queue_id,target_container){
		makeRequest('/tickets/get-tickets-for-queue/'+queue_id,{}, function(response){
			if(response.success) {
				$('#tickets-container').find('.tab-pane').removeClass('active in');
				$(target_container).html(response.html);
				$(target_container).addClass('active in');
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		},'GET');
	}
	if($('#queue_tabs').length){
		var active_tab = $('#queue_tabs').find('li.active');
		var target_container = active_tab.find('a').attr('href');
		var queue_id = active_tab.data('queue_id');
		getTicketsForQueue(queue_id,target_container);


		$('#queue_tabs').on('click','li',function(){
			$('#queue_tabs').find('li').removeClass('active');
			$(this).addClass('active');
			var target_container = $(this).find('a').attr('href');
			var queue_id = $(this).data('queue_id');
			getTicketsForQueue(queue_id,target_container);
		});

		$('#tickets-container').on('click','a',function(e){
			e.stopPropagation();
			var target = $(this).attr('href');
			window.location.href = target;
		});
	}

	$('#tickets-container div').first().addClass('in active');
	
	var ticket_id = $('#ticket-container').data('ticket-id');
	
	var success_message = function(text){
		if(!text){
			text = 'Updated';
		}
		$('.update-success').fadeIn().text(text);
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	};
	
	var error_message = function(text){
		$('.update-error').fadeIn().text(text);
		setTimeout(function(){
			$('.update-error').fadeOut();
		},5000);
	};
	
	var checkErrors = function(response){
		if(response.success != true){
			if(response.error){
				if(response.error.length > 1){
					error_message(response.error);
					return false;
				}
			}
			error_message('an unknown error occurred');
			return false;
		}
		return true;
	}
	
	if($('.select2').length){
		$('select.select2').select2({width:'100%'});
		$('select.select2.multi').select2({width:'100%', 'tags' : true});

		$('select.select2contactsearch').select2({
			ajax: {
				url: function (params) {
					return '/raw_search?search=' + params.term;
				},
				dataType: 'json',
				processResults: function (data) {

					var usable_data = [];


					//loop over our data to get it in a format usable by select2
					if(data.success == true){					
						$.each(data.results.contact, function(match_type, matches){
							$.each(matches, function(i, contact_data){
								usable_data.push({
									'id' : contact_data.id,
									'text' : contact_data.first_name + ' ' + contact_data.last_name + ' - ' + contact_data.telephone
								});
							});

						});

					}

					return {
						results: usable_data
					};
				}
			},
			 width: '100%'
		});
		
		$('select.select2assigneesearch').select2({
			ajax: {
				url: function (params) {
					return '/user_search?search=' + params.term + '&queue_id=' + $(this).data('queue-id');
				},
				dataType: 'json',
				processResults: function (data) {

					var usable_data = [];


					//loop over our data to get it in a format usable by select2
					if(data.success == true){					
						$.each(data.results, function(index, match){
							usable_data.push({
								'id' : match.id,
								'text' : match.firstname + ' ' + match.lastname
							});

						});

					}

					return {
						results: usable_data
					};
				}
			},
			 width: '100%'
		})
	}

	$('#ticket_side_bar').on('click', '#edit-ticket-info', function() {
		
		//show all the inputs 
		$('#ticket_side_bar').find('.ticket-info').toggle();
		$('#ticket_side_bar').find('.ticket-edit').toggle();
		
		/*$.ajax({
			url: '/script-groups/edit/'+script_group_id,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#script-group-steps').html(response.html);
					script_groups_sortable();
					$('.script-group-edit-panel').show();
					$('#all-script-steps  div.delete-trigger').css('display', 'none');
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});*/
	
	});
	
	$('.ticket-info-block').on('change', 'select', function(){
		if(ticket_id == undefined){
			var ticket_id = $(this).parent().parent().parent().data("ticket-id"); 
		}
		$('#ticket_info_save_'+ticket_id).show();
	});
	
	$('.ticket-links').on('change', 'select', function(){
		
		if($(this).val() == null) {
			return;
		}
		
		var that = $(this);
		
		var contact_id = $(this).val();
		var contact_ids = [contact_id];
		$('.linked-contacts-list-item').each(function(){
			contact_ids.push($(this).data('contact-id'));
		});
		
		var selected_options = contact_ids;		
		if(selected_options == null){
			selected_options = [];
		}
		
		var type = $(this).data('type');
		
		var data = {
			'type' : type,
			'links' : selected_options
		};
		
		if(ticket_id == undefined){
			var ticket_id = $(this).data("ticket-id"); 
		}
		
		$.ajax({
			url: '/ticket/edit/links/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					$('.linked-contacts-list').replaceWith(response.html);
					success_message();
					that.val(null).trigger('change');
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
		
	});
	
	
	$('#ticket_tag_links').on('change','select',function(){
		
		var selected_options = $(this).val();
		
		if(selected_options == null){
			selected_options = [];
		}
		
		var data = {
			'tags' : selected_options
		};
		
		if(ticket_id == undefined){
			var ticket_id = $(this).data("ticket-id"); 
		}
		
		$.ajax({
			url: '/ticket/edit/tags/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	$('.ticket-links').on('click', '.remove', function(){

		var contact_id = $(this).data('contact-id');
		var contact_ids = [];
		$('.linked-contacts-list-item').each(function(){
			if($(this).data('contact-id') != contact_id ) {
				contact_ids.push($(this).data('contact-id'));
			}
		});
		
		var selected_options = contact_ids;		
		if(selected_options == null){
			selected_options = [];
		}
		
		var type = 'contact';
		
		if(ticket_id == undefined){
			var ticket_id = $(this).data("ticket-id"); 
		}
		
		var data = {
			'type' : type,
			'links' : selected_options
		};
		
		$.ajax({
			url: '/ticket/edit/links/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					$('.linked-contacts-list').replaceWith(response.html);
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	$('.ticket-side-bar').on('click', '.ticket-info-save', function() {
		
		if(ticket_id == undefined){
			var ticket_id = $(this).data("ticket-id"); 
		}
		var  target_parent = $(this).closest('.ticket-list-item-block').find('.ticket-list-item-status');
		
		var ticket_status = $('#ticket_status_select_'+ ticket_id).val();
		var ticket_asssignee = $('#assignee_change_'+ ticket_id).val();
		var ticket_queue = $('#ticket-queue-'+ ticket_id).val();
		var ticket_priority = $('#ticket_priority_select_'+ ticket_id).val();

		var data = {
			'status' : ticket_status,
			'assignee' : ticket_asssignee,
			'queue' : ticket_queue,
			'priority' : ticket_priority
		};

		$.ajax({
			url: '/ticket/edit/info/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
					$('.ticket-info-save').hide();
					target_parent.html(response.updated_status);
				}
			},
			error: function (error) {
				if(error.error){
					error_message(error.error);
				}
			}
		});
	
	});
	
	$('.ticket-side-bar').on('change', '.ticket-queue-edit-select', function(){
		var select = $(this).closest('div.ticket-info-section').find('select.select2assigneesearch')
		if($(this).val != $(select).data('queue-id')){
			$(select).data('queue-id', $(this).val()).val('').trigger('change');
		}
		
	});
	
	
	var ticket_description_holder = '';
	
	$('.ticket-content').on('click', '#edit-ticket-description', function(){
		$('#ticket_description').hide();
		$('#ticket_description_edit').show();
		ticket_description_holder = $('#ticket_description_edit').val();
		$('#ticket_title').hide();
		$('#ticket_title_edit').show();
		$('#ticket_description_edit').removeAttr('readonly');
		$(this).hide();
		$('#ticket_content_save').show();
		$('#ticket_content_undo').show();
		
	});
	
	$('.ticket-content').on('click', '#ticket_content_undo', function(){
		$('#ticket_title').show();
		$('#ticket_title_edit').hide();
		$('#ticket_title_edit').val($('#ticket_title').text())
		$('#ticket_description_edit').attr('readonly', true);
		$('#ticket_description_edit').val(ticket_description_holder);
		$(this).hide();
		$('#ticket_content_save').hide();
		$('#edit-ticket-description').css('display','inline-block');
		$('#ticket_description').show();
		$('#ticket_description_edit').hide();
	});
	
	$('.ticket-content').on('click', '#ticket_content_save', function(){
		
		var new_title = $('#ticket_title_edit').val();
		var new_desciption = $('#ticket_description_edit').val();
		
		var data = {
			'title' : new_title,
			'description' : new_desciption
		};
		
		$.ajax({
			url: '/ticket/edit/header/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
					$('#ticket_title').show();
					$('#ticket_title').text(new_title);
					$('#ticket_title_edit').hide();
					$('#ticket_description_edit').attr('readonly', true);
					$(this).hide();
					$('#ticket_content_save').hide();
					$('#edit-ticket-description').css('display','inline-block');
					$('#ticket_content_undo').hide();
					$('#ticket_description').show();
					$('#ticket_description').text(new_desciption);
					$('#ticket_description_edit').hide();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
	});
	
	var filterItems = function(element, to_search, to_filter){
		$(element).parent().parent().parent().find('div.ticket-item').hide();

		if(to_search != ''){
			var items = $(element).parent().parent().parent().find('div.ticket-item');
			$(items).each(function(){
				if($(this).text().toLowerCase().indexOf(to_search.toLowerCase()) != -1){
					$(this).show();
				}
			});
		}
		else{
			$(element).parent().parent().parent().find('div.ticket-item').show();
		}

		if(to_filter != 'all'){
			if(to_filter == 'overview'){
				$(element).parent().parent().parent().find('div.ticket-item[data-type="transaction"]').hide();
			}
			else{
				$(element).parent().parent().parent().find('div.ticket-item[data-type!="'+to_filter+'"]').hide();
			}
		}
	};
	
	$('#ticket-timeline-tabs').on('click', '.filter_events', function(){
		//console.log('here');
		var to_filter = $(this).find('span').data('value');
		$('.filter_events').removeClass('tab-active');
		$(this).addClass('tab-active');
		var to_search = $('#ticket_item_search').val();
		
		filterItems($(this), to_search, to_filter);
		
	});
	
	
	$('.ticket-timeline').on('keyup', '#ticket_item_search', function(){
		var to_search = $(this).val();
		var to_filter = $('#ticket-timeline-tabs').find('.tab-active').find('span').data('value');	
		filterItems($(this), to_search, to_filter);		
	});
	
	$('.ticket-item').on('click','.toggle-ticket-item-content', function(){
		
		if($(this).text() == 'Expand') {
			$(this).text('Hide');
		}
		else if($(this).text() == 'Hide'){
			$(this).text('Expand');
		}
		
		var item_content = $(this).parent().parent().find('.ticket-item-content');
		item_content.toggle();		
	});
	
	
	
	
	//onclick create email  
	$('#ticket-container').on('click','#ticket-create-email',function(e){
		email_obj.clear();
		email_obj.setRenderMode('raw');
		email_obj.setEntityId(ticket_id);
		email_obj.setType('ticket');
		email_obj.startFlow();
	});	
	
	$('.ticket-actions').on('click', '.btn-snooze', function(){
		
		var minutes = $(this).data('length');
		
		var data = {
			'minutes' : minutes
		};
		
		if(ticket_id == undefined){
			var ticket_id = $(this).parent().parent().parent().data("ticket-id"); 
		}
		
		$.ajax({
			url: '/ticket/snooze/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	var modal_trigger = 0;

	$('body').on('click', '.modal-backdrop,.modal', function (e) {
		var targetClass = e.originalEvent.target.className
		if(targetClass.match(/\bmodal-backdrop\b/) || targetClass.match(/\bmodal\b/)) {
			e.stopPropagation()
		}
	})

	$('.modal').modal({ show: false, backdrop: 'static' })

	$('.modal').on('show.bs.modal', function (e) {
		modal_trigger = $(e.relatedTarget).data('ticket-id');
		//console.log(modal_trigger);
	});
	
	$('#confirmDelete.ticket-modal').on('click', '#really_really_delete', function(){
		
		if(ticket_id == undefined){
			if(modal_trigger != 0){
				var ticket_id = modal_trigger;
				//console.log(ticket_id);
			}
		}
		var ticket_item = $('.ticket-list-item-block[data-ticket_id='+ticket_id+']');
		
		$.ajax({
			url: '/ticket/delete/' + ticket_id,
			type:"GET",
			dataType: 'json',
			success: function(response) {
				if(checkErrors(response)){
					success_message();
					ticket_item.remove();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});

	
	$('.ticket-actions').on('click', '.add-ticket-task', function(){
		
		var that = $(this);
		
		var ticket_id = $(this).data("ticket_id");
		var task_id = $(this).data('task-id');
		var parent = $(this).closest('.add-ticket-task-container');
		var task_title = $(parent).find(':input[name="task_title"]').val();
		var task_description = $(parent).find(':input[name="task_description"]').val();
		
		if(task_title.length < 2){
			error_message('Please Enter a descriptive Task Title');
			return false;
		}		
				
		var user_id = $(parent).find(':input[name="task_user_id"]').find(":selected").val();
		
		var due_date = $(parent).find(".todo-date").val();
		
		var data = {
			'ticket_id' : ticket_id,
			'task_title' : task_title,
			'task_description' : task_description,
			'user_id' : user_id,
			'due_date' : due_date
		};
		
		$.ajax({
			url: '/ticket/add/task',
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
					$('.ticket-actions input[data-task-name]').val('');
					if(that.parent().find('div#ticket-tasks-list').length > 0){
						$('div#ticket-tasks-list').append(response.html);
					} 
					else {
						$('#ticket_actions').find('.quick-actions-tasks').html(response.html);
						// var tasks_container = $('<div id="ticket-tasks-list" class="ticket-tasks-container"></div>');
						// that.parent().append(tasks_container);
						// that.parent().find('div#ticket-tasks-list').append(response.html);
					}
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	$('.ticket-actions').on('click', '.edit-ticket-task', function(){
		
		var that = $(this);
		var parent = $(this).closest('.ticket-task-edit-section');
		var ticket_id = $(this).data("ticket_id");
		var task_id = $(this).data('task-id');
		var task_title = $(parent).find(':input[name="task_title"]').val();
		var task_description = $(parent).find(':input[name="task_description"]').val();
		
		if(task_title.length < 2){
			error_message('Please Enter a descriptive Task Title');
			return false;
		}		
				
		var user_id = $(parent).find(':input[name="task_user_id"]').find(":selected").val();
		
		var due_date = $(parent).find(".todo-date").val();
		
		var data = {
			'ticket_id' : ticket_id,
			'task_id' : task_id,
			'task_title' : task_title,
			'task_description' : task_description,
			'user_id' : user_id,
			'due_date' : due_date
		};
		
		$.ajax({
			url: '/ticket/edit/task',
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
					$('#ticket_actions').find('.quick-actions-tasks').html(response.html);
					// $(parent).find('search-ticket-tasks').val('');
					// if($(parent).parent().parent().parent().find('div#ticket-tasks-list').length > 0){
					// 	$('div#ticket-tasks-list').append(response.html);
					// 	$(parent).parent().remove();
					// } 
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	$('.ticket-actions').on('click', '.ticket-task-check', function(){
		
		var task_id = $(this).data('task-id');
		
		var that = $(this);
		
		$.ajax({
			url: '/ticket/toggle/task/' + task_id,
			type:"GET",
			dataType: 'json',
			success: function(response) {
				if(checkErrors(response)){
					if(isset(response.task_complete)){
						if(response.task_complete){
							$(that).find('.task-incomplete').hide();
							$(that).find('.task-completed').show();
						}
						else{
							$(that).find('.task-incomplete').show();
							$(that).find('.task-completed').hide();
						}
					}
					$(that).parent().toggleClass('ticket-task-red ticket-task-green');
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	var showActionTab = function(target){
		$(target).siblings().hide();
		$(target).toggle();
	}
	
	$('#ticket_content').on('click', '#response_tab_toggle', function(){
		showActionTab('#response_tab');
	});
	
	$('#ticket_content').on('click', '#note_tab_toggle', function(){
		showActionTab('#note_tab');
	});
	
	$('#ticket_content').on('click', '#event_tab_toggle', function(){
		showActionTab('#event_tab');
	});
	
	$('#ticket_content').on('click', '#make_note', function(){
		
		var note = $('#new_note_content').val();
		var header = $('#note_header').val();
		
		if(note.length < 4){
			error_message('please eneter a descriptive note');
			return;
		}
		
		var data = {
			'note' : note,
			'header' : header
		};
		
		$.ajax({
			url: '/ticket/new/note/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data : data,
			success: function(response) {
				if(checkErrors(response)){
					$('#ticket_items').html(response.html)
					$('#note_tab input').val('');
					$('#note_tab textarea').val('');
					$('#note_tab').hide();
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});

	$('#ticket-container').on('click','.ticket-info-section .update-requester-email', function(){
		var email = $(this).parent().find('input').val();
		var data = {
			'requester_email' : email
		};
		makeRequest('/ticket/update/requester/' + ticket_id, data, function(response){
			if(response.success){
				success_message();
			}
			else if(response.error){
				error_message(response.error);
			}
		}
		,'POST');
	});
	
	$('#ticket_content').on('click', '#make_response', function(){
		var subject = $('#response_subject').val();
		var body = $('#response_body').val();
		var to = $('#ticket_requester').find('input[name="ticket_requester"]').val();
		
		email_obj.clear();
		email_obj.setEntityId(ticket_id);
		email_obj.setType('ticket');
		email_obj.setSubject(subject);
		//11 is the free form text template
		email_obj.setTemplate(11);
		email_obj.setBody(body);
		email_obj.sendEmail(to, function(){
			success_message();
			window.location.reload();
		});
		
	});
	
	$('.ticket-make-event-button-container').on('click', '#make_event', function(){
		
		var select = $(this).parent().parent().find('select');
		
		var event = $(select).val();
		
		if(event === null){
			error_message('please select and event');
			return;
		}
		
		var ticket_id = $(this).data('ticket-id');
		
		var data = {
			'event_id' : event
		};
		
		$.ajax({
			url: '/ticket/new/event/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data : data,
			success: function(response) {
				if(checkErrors(response)){
					if(response.html){
						createModal(response.target, response.html);
						$('#editEventActionAttributes  input.date-filter').daterangepicker({
							"locale": {
								"format": 'DD/MM/YYYY HH:mm'		
							},
							"timePicker": true,
							"singleDatePicker": true,
							"showDropdowns": true,
							"timePicker24Hour" : true
						});
					}
					else{
						success_message();
						if(response.redirect){
							window.location = response.url;
						}
					}
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});



	var addToActionAttributePayload = function(payload, key, value){
		var data = {
			'action_groups': {
				1 : {
					'action_group_id':1,
					'actions': {
						6 : {
							'action_id' : 5,
							'action_group_action_link_id' : 6,
							'attributes' : {
								1232 : {
									'attribute_id' : 7,
									'value' : 'test',
									'link_id' : 1232
								}
							}
						}
					}
				}
			}
		}
	}

	$('.ticket-links').on('click','.expand', function(){
		var ticket_id = $(this).parent().data("ticket_id");
		var contact_id = $(this).parent().data("contact-id");
		var target = $('#ticket-'+ticket_id+'-contact-'+contact_id+'-detail');
		$(target).toggle();
		if($(target).is(':visible')){
			$(target).parent().find('.expand i').removeClass('fa-caret-down');
			$(target).parent().find('.expand i').addClass('fa-caret-up');
		}
		else{
			$(target).parent().find('.expand i').addClass('fa-caret-down');
			$(target).parent().find('.expand i').removeClass('fa-caret-up');
		}
	});
	
	
	$('div.ticket-actions').on('click', '.ticket-task-info', function(){
		$(this).parent().find('.ticket-task-edit-section').hide();
		$(this).parent().find('.ticket-task-info-section').toggle();
	});
	
	$('div.ticket-actions').on('click', '.ticket-task-edit', function(){
		$(this).parent().find('.ticket-task-info-section').hide();
		$(this).parent().find('.ticket-task-edit-section').toggle();
	});


	$('.ticket-queue-edit-panel').on('click', '#queue_password_edit', function(){
		var container = $(this).closest('.ticket-queue-edit-input-group');
		$(container).find('.form-control').toggle();
		var change_icon = $(this).data('change-icon');
		var i = $(this).find('i');
		var current_classes = i.attr('class');
		var classes_array = current_classes.split(' ');
		var new_classes = '';
		var old_class='';
		$.each(classes_array, function(index, value){
			if(value.indexOf('fa-') === -1){
				new_classes += value + ' ';
			}
			else{
				old_class = value;
			}
		});
		new_classes += change_icon;
		i.attr('class', new_classes);
		$(this).data('change-icon', old_class);
	});
});