//parent to everything -- if you are inclined to copy pastey this file changing this variable might be helpful to you
var scope = 'script_inputs';

var column_1 = 'script_steps';
var column_2 = 'script_input_groups';
var column_3 = 'script_inputs';

var edit_panel = 'edit_panel';

var base_url = '/script_inputs';


var makeSortable = function () {
	$(".sort-list").sortable().sortable('destroy');
	//$('.drag-list').draggable().draggable('destroy');
	var copyHelper = false;
	$(".sort-list").sortable({
		connectWith: ".drop-zone",
		update: sortUpdate,
		helper: function (e, li) {
			if($(this).hasClass('drag-list')){
				copyHelper = li.clone().insertAfter(li);
				return li.clone();
			}
			return li;
		},
		stop: function () {
			copyHelper && copyHelper.remove();
		},
		revert : true
	}).disableSelection();
	// $('.drag-list').draggable({
	// 	connectToSortable: ".drop-zone",
	// 	helper: "clone",
	// 	revert: "invalid"
	// }).disableSelection();
};

var getBuildInputInstance = function(id){
	showLoader();
	makeRequest(base_url + '/' + column_2 + '/edit_attributes', {'input_id': id}, function(response){
		if(response.success){

			if(response.html){
				createModal('input_attribute_edit_modal', response.html);
			}
			else{
				var group_data = buildPostData();
				var data = {
					'groups' : group_data
				};
				updateSever(data);
			}

			success_message();
		}
		else{
			handleError(response);
		}
		hideLoader();
	});
}

var editInputInstance = function(link_id){
	showLoader();
	makeRequest(base_url + '/' + column_2 + '/edit_existing_attributes', {'link_id': link_id}, function(response){
		if(response.success){

			if(response.html){
				createModal('input_instance_attribute_edit_modal', response.html);
			}
			else{
				error_message('no editable attributes');
			}
		}
		else{
			handleError(response);
		}
		hideLoader();
	});
}

var sortUpdate = function(e, ui){
	//check that we only run once per drop not twice please
	if(this === ui.item.parent()[0]) {

		$(ui.item).clone().appendTo(ui.sender);
		var input_id = $(ui.item).data('input-id');
		if(!$(ui.item).data('link-id') && !$(ui.item).data('id')){
			getBuildInputInstance(input_id);
		}
		else{
			var group_data = buildPostData();
			var data = {
				'groups' : group_data
			};
			updateSever(data);
		}

		// var data = buildPostData();
		// updateSever(data);
	}
}

var buildPostData = function(){

	//structure to build 
	/**
	 * [
	 * 		{
	 * 			group : id
	 * 			inputs : [
	 * 				id,
	 * 				id	
	 * 			]
	 * 		}
	 * ]
	 * 
	 */
	

	var step_id = $('#' + column_2 +' .create-new-button').data('parent-id');

	var data = {'step_id' : step_id, 'groups' : {}};
	
	var groups = $('#script_groups').find('.script-input-group');

	$.each(groups, function(i, group){
		var group_data = {'items' : {}};
		group_data.group_id = $(group).data('id');
		var input_container = $('.group-inputs[data-group-id="' + group_data.group_id + '"]');
		var inputs = $(input_container).find('.script-input');
		$.each(inputs, function(i, input){
			group_data.items[i] = {
				'link_id' : $(input).data('link-id'),
				'input_id' : $(input).data('input-id')
			};
		});
		data.groups[i] = group_data;
	});

	return data;

}

var updateSever = function(data = {}){
	makeRequest(base_url + '/' + column_2 + '/update', data, function(response){
		if(response.success){
			if(response.html){
				$('#' + column_2).html(response.html);
				makeSortable();
			}
			success_message();
		}
		else{
			handleError(response);
		}
	});
}

var available_actions = {
	'reload' : function(){
		//window.location.reload();
	}
};

var handleError = function(response){
	var error = 'oops, something is wrong, have you tried turning it off and on again?';
	if(response.error) {
		error = response.error;
	}
	error_message(error);

	if(isset(response.action)){
		if(isset(available_actions[response.action]) && typeof (available_actions[response.action]) == 'function'){
			available_actions[response.action]();
		}
	}
};

$(document).ready(function() {
	$('#' + scope).on('click', '#' + column_1 + ' .edit-trigger', function(){

		var id = $(this).data('id');
		var group = $(this).data('group');

		var data = {
			'id' : id,
		};
		makeRequest(base_url + '/' + column_1 + '/edit', data, function(response){
			if(response.success && response.html){
				$('#' + column_2).html(response.html);
				$('#' + edit_panel).show();
				makeSortable();
			}
			else{
				handleError(response);
			}
		})
	});
	
	$('#' + scope).on('click', '#' + column_2 + ' .create-new-button', function(){

		var parent_id = $(this).data('parent-id');

		var name = $(this).parent().find('.3-column-search').val();

		if(!name.length || name.length < 5){
			error_message('please enter a decent name for the ' + column_2);
			return;
		}
		var data = {
			'parent_id' : parent_id,
			'name' : name
		};

		makeRequest(base_url + '/' + column_2 + '/create', data, function(response){
			if(response.success && response.html){
				$('#' + column_2).html(response.html);
				$('#' + edit_panel).show();
				makeSortable();
			}
			else{
				handleError(response);
			}
		})
	});

	$('#' + scope).on('click', '#' + column_2 + ' .delete-input-group-trigger', function () {

		//Could have used the function below 'column_2 .delete-trigger', but feels messy

		var input_group = $(this).closest('li');

		if(isset($(input_group).data('id'))) {

			var input_group_id = $(input_group).data('id');
			var data = {
				input_group_id: input_group_id
			};

			makeRequest(base_url + '/' + column_2 + '/delete_group', data, function (response) {
				if (response.success) {
					$(input_group).next().find('.script-input-group-inputs').remove();
					$(input_group).remove();
					success_message(response.message + ' ' + $(input_group).find('.name').text());
				}
				else {
					error_message('Could not match script input group');
				}
			});
		}
		else {
			error_message('Unable to process input group deletion.');
		}

	});

	$('#' + scope).on('click', '#' + column_2 + ' .delete-group-inputs-trigger', function(){

		//need to remove the item and update the server -- but if we are removing a group then we need to remove all of the children

		var item = $(this).closest('li');

		var edit_button = $(item).find('.edit-trigger');

		var target = $(edit_button).data('target');
		
		if(target && $(target).length){
			$(target).remove();
		}

		$(item).remove();

		var data = {
			'groups' : buildPostData()
		};

		updateSever(data);
	});
	
	$('#' + scope).on('click', '#' + column_3 + ' .edit-trigger' , function(){

		var id = $(this).data('id');

		makeRequest('/views/modal/edit-script-input-modal', {'script_input_id' : id}, function(response){
			if(isset(response.html)){
				createModal('edit_script_input_modal', response.html)
			}
			else{
				var error = 'could not create modal';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('#' + scope).on('click', '#' +column_2 + ' .sub-list .edit-trigger' , function(){

		var link_id = $(this).closest('.script-input').data('link-id');
		editInputInstance(link_id);

	});

	$('#' + scope).on('click', '#' + column_3 + ' .create-new-button', function(){

		var name = $(this).parent().find('.3-column-search').val();

		if(!name.length || name.length < 5) {
			error_message('please enter a decent name for the ' + column_3);
			return;
		}

		var modal = $('#create_script_input_modal');
		modal.find('.name-input').val(name);
		modal.find('#save_values').attr('id', 'next');
		modal.find('.values-section').collapse('hide');
		modal.find('.config-section').collapse('show');
		modal.modal();
	});

	$('#create_script_input_modal').on('click', '#next', function(){

		var modal_content = $(this).closest('.modal-content');

		var name = $(modal_content).find('.name-input').val();

		if(name.length < 5) {
			error_message('please enter a descriptive name');
			return false;
		}

		var type = $(modal_content).find('.type-input').val();
		var attributes = [];
		var editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each(function() {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function() {
			editable.push($(this).val());
		});

		var data = {
			'name': name,
			'type': type,
			'attributes': attributes,
			'ediable_attributes': editable
		};

		var that = this;

		makeRequest(base_url + '/' + column_3 + '/create', data, function(response){
			if(response.success && response.html) {
				modal_content.find('.values-section').html(response.html).collapse('show');
				modal_content.find('.config-section').collapse('hide');
				$(that).text('update');
				$(that).attr('id', 'save_values');
				$(that).data('input-id', response.input_id);
				$('.select2').select2({
					'width': '100%'
				});
				$('.select2.mutli').select2({
					'tags': true,
					'tokenSeparators': [','],
					'width':'100%'
				});
				success_message();
			}
			else{
				handleError(response);
			}
		});
	});


	$('#create_script_input_modal').on('click', '#save_values', function() {
		var input_id = $(this).data('input-id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form');

		var data = readAttributeValues(form);

		var that = this;
		makeRequest(base_url + '/' + column_3 + '/update/' + input_id, data, function(response){
			if(checkErrors(response)) {
				if(response.html) {
					$('#' +scope +  ' #' + column_3 + ' ul').append(response.html);
				}
				var modal = $(that).closest('.modal');
				$(modal).modal('hide');
				$(modal).find('.values-section').html('');
				success_message();

			}
		});
	});

	$('body').on('click', '#edit_script_input_modal #next', function(){
		var modal_content = $(this).closest('.modal-content');

		var name = $(modal_content).find('.name-input').val();

		if(name.length < 5) {
			error_message('please enter a descriptive name');
			return false;
		}

		var type = $(modal_content).find('.type-input').val();
		var attributes = [];
		var editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each(function() {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function() {
			editable.push($(this).val());
		});

		var id = $(this).data('id');

		var data = {
			'id' : id,
			'name': name,
			'type': type,
			'attributes': attributes,
			'ediable_attributes': editable
		};

		var that = this;

		makeRequest(base_url + '/' + column_3 + '/edit', data, function(response){
			if(response.success && response.html) {
				modal_content.find('.values-section').html(response.html).collapse('show');
				modal_content.find('.config-section').collapse('hide');
				$(that).text('update');
				$(that).attr('id', 'save_values');
				$(that).data('input-id', response.input_id);
				$('.select2').select2({
					'width': '100%'
				});
				$('.select2.mutli').select2({
					'tags': true,
					'tokenSeparators': [','],
					'width':'100%'
				});
				success_message();
			}
			else{
				handleError(response);
			}
		});
	});

	var window_fields = [
		'specific-from',
		'specific-from-metric',
		'specific-to',
		'specific-to-metric',
		'custom-from-date',
		'custom-to-date',
		'predefined-from-date',
		'predefined-to-date'
	];

	var readAttributeValues = function(form){
		var form_data = form.serializeArray();

		var data = {};

		$.each(form_data, function(i, serialized_data) {
			if(isset(data[serialized_data['name']])){
				if(typeof(data[serialized_data['name']]) != 'object'){
					data[serialized_data['name']] = [data[serialized_data['name']]];
				}
				data[serialized_data['name']].push(serialized_data['value']);
			}
			else{
				data[serialized_data['name']] = serialized_data['value'];
			}
		});

		form = $(form).closest('form');
		//unchecked checkboxes do not get returned as part of serializeArray -- because that makes all the sense. So lets do that by hand
		var uncheckedboxes = $(form).find(':input[type="checkbox"]:not(:checked)');
		$.each(uncheckedboxes, function(i, checkbox){
			data[$(checkbox).attr('name')] = 0;
		});

		//handle window fields
		if($(form).find('.time-selectors-1 .btn:visible').length){
			var predefined_window_values = $(modal_content).find('.time-selectors-1 input').serializeArray();
			$.each(predefined_window_values, function(i, input_data){
				data[input_data.name] = input_data.value;
			});
		}

		$.each(data, function(key, value){
			if(window_fields.indexOf(key) !== -1){
				if(!isset(data.window)){
					data.window = {};
				}
				var key_parts = key.split('-');
				data.window.type = key_parts.shift();
				data.window[key_parts.join('-')] = value;
				delete data[key];
			}
		});
		return data;
	};

	$('body').on('click', '#edit_script_input_modal #save_values', function() {
		var input_id = $(this).data('input-id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form :input:visible');
		
		var data = readAttributeValues(form);

		var that = this;
		makeRequest(base_url + '/' + column_3 + '/update/' + input_id, data, function(response){
			if(checkErrors(response)) {
				var modal = $(that).closest('.modal');
				$(modal).modal('hide');
				$(modal).find('.values-section').html('');
				success_message();

			}
		});
	});
	
	$('body').on('click', '#input_attribute_edit_modal #save_values', function() {
		var input_id = $(this).data('id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form :input:visible');
		
		var attribute_data = readAttributeValues(form);
		var group_data = buildPostData();

		var data = {
			'input' : {
				'id' : input_id,
				'attributes' : attribute_data
			},
			'groups' : group_data
		};

		var that = this;
		updateSever(data);
		var modal = $(that).closest('.modal');
		$(modal).modal('hide');
		$(modal).find('.values-section').html('');
	});
	
	$('body').on('click', '#predefined-times button', function(e){
		e.preventDefault();
	});

	$('body').on('click', '#input_instance_attribute_edit_modal #save_values', function() {
		var link_id = $(this).data('id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form :input:visible');
		
		var attribute_data = readAttributeValues(form);

		var step_id = $('#' + column_2 +' .create-new-button').data('parent-id');

		var data = {
			'input' : {
				'link_id' : link_id,
				'attributes' : attribute_data
			},
			'step_id' : step_id
		};

		var that = this;
		makeRequest(base_url + '/' + column_2 + '/update_existing_attributes', data, function(response){

			if(response.success && response.html){

				$('#' + column_2).html(response.html);
				makeSortable();

				var modal = $(that).closest('.modal');
				$(modal).modal('hide');
				$(modal).find('.values-section').html('');
				success_message();

			}
			else{
				handleError(response);
			}
		});
	});

	$('#' + scope).on('click', '#' + column_2 + ' .script-input-group .edit-trigger' , function(){

		//edit script input group
		//build and show modal 

		var group_id = $(this).data('id');
		var data = {
			'input_group_id' : group_id
		};

		makeRequest(base_url + '/' + column_2 + '/edit_group', data, function(response){

			if(isset(response.success) && response.success && response.html){
				createModal('input_group_edit_modal', response.html);
				$('.select2.mutli.custom_classes').select2({
					'tags': true,
					'tokenSeparators': [','],
					'width':'100%'
				});
			}
			else{
				handleError(response);
			}
		});
	});

	$('body').on('click', '#repeatable_toggle', function () {
		if($(this).val() == 'off') {
			$(this).val('on');
		} 
		else {
			$(this).val('off');
		}
	});

	$('body').on('click', '#input_group_edit_modal #save_values', function(){
		//get any values from the interface and post to the server for storage

		var inputs = $('#input_group_edit_modal div.script_input_group_edit_section :input');
		var results = {};
		$.each(inputs, function(i, input){
			var name = $(input).attr('name');
			if(name){
				results[name] = $(input).val();
			}
		});

		var data = {
			group_id : $(this).data('id'),
			results : results
		};
		
		makeRequest(base_url + '/' + column_2 + '/update_group', data, function(response){
			if(isset(response.success) && response.success){
				success_message();
				$('#input_group_edit_modal').modal('hide');
			}
			else{
				handleError(response);
			}
		});
	});

	/**
	 * script input group conditions event listeners
	 */

	$('body').on('click', '#input_group_edit_modal #add_condition', function() {
		var parent = $(this).parent().parent();
		var new_holder = parent.find('#new_condition');
		var html = new_holder.html();
		new_holder.before(html);
		new_holder.prev().find('.select2').select2({'wdidth' : '100%'});
	});
	
	$('body').on('click', '#input_group_edit_modal #save_condition', function() {
		var parent = $(this).parent().parent();
		var data = { 
			'group_id' : $(this).data('group'),
			"condition_id" : $(this).data('condition'),
			"key" : parent.find('.profile_key').val(),
			"value" : parent.find('#condition_value').val(),
			"action" :  parent.find('#condition_action').val(),
			"cascade" : parent.find('#condition_cascade').val()
		};

		makeRequest(base_url + '/' + column_2 + '/update_group_condition', data, function(response){
			if(response.success && response.html){
				var new_container = $(response.html);
				parent.replaceWith(new_container);
				new_container.find('.select2').select2({'width' : '100%'});
				success_message()
			}
			else{
				handleError(response);
			}
		})
	});
	
	$('body').on('click', '#input_group_edit_modal #delete_condition', function(){
		var condition = $(this).data('condition');
		var parent = $(this).parent();
		if(condition == 'new'){
			// it is a new one - just remove the html 
			parent.remove();
			return;
		}
		else{
			var group_id = $(this).data('group');
			var data = {
				'group_id' : group_id,
				'condition_id' : condition
			};
			makeRequest(base_url + '/' + column_2 + '/delete_group_condition', data, function(response){
				if(response.success){
					parent.remove();
					success_message()
				}
				else{
					handleError(response);
				}
			})
		}
		
	});

	var checkExistingInputs = function () {
		var type = $('.ledger-select').val();
		var errors = true;
		$.each($('.ledger-input-container label'), function () {
			var existing_value = $(this).text();
			if(existing_value == type) {
				errors = true;
			} 
			else {
				errors = false;
			}
		});
		return errors;
	};

	$('body').on('click', '.add-new-ledger-item-span', function () {
		var type = $('.ledger-select').val();
		var template = $('.ledger-input-container').find('.ledger-template-item');
		var value = $('.ledger-type-value').val();
		var input_group = template.clone().appendTo('.ledger-input-container').removeClass('hide').removeClass('ledger-template-item');
		$('.ledger-type-value').val('');

		$(input_group).find('label').text(type);
		$(input_group).find('input').attr('name', type);
		$(input_group).find('input').val(value);

		var ledger = $(this).closest('.script-input-container').find('.ledger-input-container');
		updateLedgerTotal(ledger);
	});

	$('body').on('click', '.edit-ledger-item-span', function () {
		$(this).parent().find('input').removeAttr('disabled');
	});

	$('body').on('click', '.remove-ledger-item-span', function () {
		$(this).parent().remove();
		var ledger = $(this).closest('.ledger-input-container');
		updateLedgerTotal(ledger);
	});

	$('body').on('blur', '.ledger-container input', function () {
		if(!$(this).hasClass('ledger-type-value')) {
			$(this).attr('disabled', true);
			var ledger = $(this).closest('.script-input-container').find('.ledger-input-container');
			updateLedgerTotal(ledger);
		}
	});

	$('body').on('click', '#add-new-repeatable', function() {
		var that = $(this);
		var dom = $(this).closest('.repeatable').find('.repeatable-table-row.hide');
		var target = $(this).closest('.repeatable').find('.repeatable-sub-container');

		var clone_inputs = $(dom).clone().appendTo(target).removeClass('hide');
		$.each($(clone_inputs).find(':input'), function (key, value) {
            var id = Math.random().toString(36).substring(9);
			$(value).attr('id', id);
			$(value).closest('.script-input-container').find('label').attr('for', id);
			$(value).closest('.script-input-container').removeAttr('id');

			if($(value).hasClass('select-2-input')) {
				$(value).addClass('select2');
				if($(value).closest('.form-group').hasClass('customizable-options-inputs')) {
					$(value).select2({'width': '100%', tags: true});
				} 
				else {
					$(value).select2({'width': '100%'});
				}
				$(value).attr('name', id);

				$.each($(value).find('option'), function (k, option) {
					var item_val = $(option).val();
					$(option).attr('id', id+'_'+item_val);
					$(option).attr('data-select2-id',  id+'_'+item_val);
				});

				if($(value).hasClass('script-multi-select')) {
					$(value).attr('name', id+'[]');
				}
			}
		});

		var table = $(that).closest('.repeatable');
		if($(that).closest('.repeatable').hasClass('summable-inputs')) {
			var summable_table = $(table).find('.repeatable-sub-container');
			updateRepeatableSummable(summable_table);
		}
	});

	var updateLedgerTotal = function (ledger) {
		var total = 0;
		$(ledger).find($('.ledger-item-row input')).each(function (key, element) {
			if(!$(element).hasClass('hide')) {
				if(!isNaN(parseInt($(element).val(), 10))) {
					total += parseInt($(element).val(), 10);
				}
			}
		});
		$('.ledger-total-input').val(total.toString());
	};

	var updateRepeatableSummable = function (summable_table) {
		var total = [];
		var skip = 0;
		$.each(summable_table.find('.repeatable-table-row'), function (k, row) {
			var cell_count = $(row).find(':input').length;
			skip = cell_count;
			if (!$(row).hasClass('hide')) {
				var i = 0;
				$.each($(row).find(':input'), function (k, input) {
					if (!isNaN(parseInt($(input).val(), 10)) && $(input).val() != "") {
						if(isNaN(total[i])) {
							total[i] = 0;
						}
						var val = parseInt(total[i], 10) + parseInt($(input).val().trim(), 10);
						total[i] = val;
					}
					cell_count--;
					i++;
				});
			}
		});

		var total_field = summable_table.closest('.repeatable').find('.total-inputs-row').find(':input');
		var total_cell_count = total_field.length;
		var i = skip - total_cell_count;
		$.each(total_field, function(k, total_input) {
			$(total_input).val(total[i]);
			i++;
		});
	};

	$('body').on('blur', '.repeatable-table-item', function () {
		if($(this).closest('.repeatable').hasClass('summable-inputs')) {
			var summable_table = $(this).closest('.repeatable').find('.repeatable-sub-container');
			updateRepeatableSummable(summable_table);
		}
	});

	$.each($('.summable-inputs'), function (k, table) {
		var summable_table = $(table).find('.repeatable-sub-container');
		updateRepeatableSummable(summable_table);
	});

	$('body').on('click', '.delete-repeatable-row', function () {
		$(this).closest('.repeatable-table-row').remove();
	});

	$('body').on('click', '.save-script-progress', function () {
		submitForm()
	});

	$('body').on('click', '.magic_step_edit', function () {
		var step = $(this).data('target');
		window.lead_script.handleStep(step);
	});

	$('body').on('change', '.script-slider', function () {
		var output_val = $(this).val();
		var output_dom = $(this).closest('.slider-container').find('.slider-output');

		$(output_dom).text(output_val);
	});
});