$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");

	$('#lead_script').on('click','#confirm-will-order',function(){
		showLoader();
		var quotes_to_confirm = [];
		quotes_to_confirm.push($('#lead_script').data("quoteid"));
		var keys_to_update  = {};
		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update
		};

		data['reset_confirmed_date'] = $(this).data('reset-confirmed-date');
		
		api.confirmOrders(data, function(response){
			if(response.success){
				window.location.href =  response.redirect_url;
			}
			else{
				hideLoader();
				if(isset(response.errrors)){
					response.errors.each(function(i,e){
						error_message(e);
					});
				}
				else{
					error_message('something went wrong');
				}
			}
		});
		
		// return;
	});

	$('.script').on('click','#confirm-orders',function(){
		showLoader();
		$('#basket_errors').hide().text('');
		
		var quotes_to_confirm = [];
		$('button.btn-remove-quote:visible').each(function(){
			quotes_to_confirm.push($(this).data('quote-id'));
		});
		
		var keys_to_update  = {};
		//additional checkout items 
		if($('#checkout_actions').length){
			var items = $('#checkout_actions').find('[data-key]');
						
			$(items).each(function(){
				//@todo handle other types of inputs - this is just for checkboxes
				var custom_type = $(this).data('type');
				if(custom_type){
					var type = custom_type;	
				}
				else{
					var type = $(this).attr('type');
				}
				if(type == 'checkbox'){
					keys_to_update[$(this).data('key')] = $(this).is(':checked') ? 'Yes' : 'No';
				}
				else if(type == 'radio'){
					if($(this).is(':checked')){
						keys_to_update[$(this).data('key')] = $(this).val();
					}
				}
				else if(type == 'conditional'){
					var condition = $(this).data('condition');
					var parts = condition.split('=');
					if(parts.length &&  isset(keys_to_update[parts[0]]) && keys_to_update[parts[0]] == parts[1]){
						keys_to_update[$(this).data('key')] = $(this).val();
					}
				}
			});
			
		}
		
		if(quotes_to_confirm.length == 0){
			$('#basket_errors').text('No quotes selected, please select a quote to confirm');
			$('#basket_errors').show();
			hideLoader();
			return;
		}
		var script = $(this).closest('#lead_script');
		if(script.find('#order_confirmation_errors').length){
			var modal_error_container = $('#order_confirmation_errors_modal').find('#order_confirmation_issues');
			var error_html = $(script).find('#order_confirmation_errors').html();
			modal_error_container.html(error_html);
			showModal('order_confirmation_errors_modal');
			hideLoader();
			return false;
		}

		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update
		};

		data['reset_confirmed_date'] = $(this).data('reset-confirmed-date');
		

		api.confirmOrders(data, function(response){
			if(response.success){
				window.location.href =  response.redirect_url;
			}
			else{
				hideLoader();
			}
		});
		
		return;
	});

	$('.script').on('click','#confirm-notification-order',function(){
		showLoader();
		$('#basket_errors').hide().text('');
		
		var quotes_to_confirm = [];
		$('button.btn-remove-quote:visible').each(function(){
			quotes_to_confirm.push($(this).data('quote-id'));
		});
		
		var keys_to_update  = {};
		//additional checkout items 
		if($('#checkout_actions').length){
			var items = $('#checkout_actions').find('[data-key]');
						
			$(items).each(function(){
				//@todo handle other types of inputs - this is just for checkboxes
				var custom_type = $(this).data('type');
				if(custom_type){
					var type = custom_type;	
				}
				else{
					var type = $(this).attr('type');
				}
				if(type == 'checkbox'){
					keys_to_update[$(this).data('key')] = $(this).is(':checked') ? 'Yes' : 'No';
				}
				else if(type == 'radio'){
					if($(this).is(':checked')){
						keys_to_update[$(this).data('key')] = $(this).val();
					}
				}
				else if(type == 'conditional'){
					var condition = $(this).data('condition');
					var parts = condition.split('=');
					if(parts.length &&  isset(keys_to_update[parts[0]]) && keys_to_update[parts[0]] == parts[1]){
						keys_to_update[$(this).data('key')] = $(this).val();
					}
				}
			});
			
		}
		
		if(quotes_to_confirm.length == 0){
			$('#basket_errors').text('No quotes selected, please select a quote to confirm');
			$('#basket_errors').show();
			hideLoader();
			return;
		}
		var script = $(this).closest('#lead_script');
		if(script.find('#order_confirmation_errors').length){
			var modal_error_container = $('#order_confirmation_errors_modal').find('#order_confirmation_issues');
			var error_html = $(script).find('#order_confirmation_errors').html();
			modal_error_container.html(error_html);
			showModal('order_confirmation_errors_modal');
			hideLoader();
			return false;
		}

		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update
		};

		data['reset_confirmed_date'] = $(this).data('reset-confirmed-date');
		
		var that = this;
		api.confirmOrders(data, function(response){
			if(response.success){
				window[$(script).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				hideLoader();
			}
		});
		
		return;
	});
	
	$('#order_confirmation_errors_modal').on('click','#submit_order_confirmation_approval',function(){
		
		var quotes_to_confirm = [];
		$('button.btn-remove-quote:visible').each(function(){
			quotes_to_confirm.push($(this).data('quote-id'));
		});
		
		var keys_to_update  = {};
		//additional checkout items 
		if($('#checkout_actions').length){
			var items = $('#checkout_actions').find('[data-key]');
						
			$(items).each(function(){
				//@todo handle other types of inputs - this is just for checkboxes
				if($(this).attr('type') == 'checkbox'){
					keys_to_update[$(this).data('key')] = $(this).is(':checked') ? 'Yes' : 'No';
				}
			});
			
		}
		
		if(quotes_to_confirm.length == 0){
			error_message('No quotes selected, please select a quote to confirm');
			return;
		}

		var manager = $('#order_confirmation_errors_modal #approval_manager').val();
		if(!manager){
			error_message('Please select the manager you have spoken with about this order');
			return;
		}

		var notes = $('#order_confirmation_errors_modal #confirmation_notes').val();

		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update,
			'manager_id' : manager,
			'notes' : notes
		};
		showLoader();
		api.confirmApprovedOrders(data, function(response){
			if(response.success){
				window.location.href =  response.redirect_url;
			}
			hideLoader();
		});
		
		return;
	});
	
	$('#lead_script').on('click','#remove_quote_button',function(){
		var parent = $(this).parent().parent();
		parent.toggleClass('removed');
		parent.find('#add_quote_button').show();
		$(this).hide();
	});
	
	$('#lead_script').on('click','#add_quote_button',function(){
		var parent = $(this).parent().parent();
		parent.toggleClass('removed');
		parent.find('#remove_quote_button').show();
		$(this).hide();
	});
		
	$('.script').on('change','input[name="user_selected_renewal_date"]',function(){
		var value = $(this).val();
		if(value == 'custom_date'){
			$('.custom-renewal-date-selector-holder').show();
		}
		else{
			$('.custom-renewal-date-selector-holder').hide();
		}
	});

	$('#lead_script').on('click','#remove_linked_quote_button',function(){
		var container = $(this).closest('.basket-products-content');
		$(container).remove();
		var quote_id = $(container).data('quote_id');
		// go remove the linked quote?
	})
	
	$('#lead_script').on('change','.toggle-basket-group-switch',function(){
		if($(this).is(':checked')){
			$($(this).closest('.product_group_row')).removeClass('disabled');
		}
		else{
			$($(this).closest('.product_group_row')).addClass('disabled');
		}
	});

	$('#lead_script, #aftersale-energy').on('click', '#confirm_pure_energy_order', function() {
		showLoader();
		var quotes_to_confirm = [];
		var keys_to_update  = {};
		if($('.toggle-basket-smart-meter-switch').length){
			if($('.toggle-basket-smart-meter-switch').is(':checked')){
				keys_to_update['energy:decline_smart_meter_install']  = true;
			}
		}
		$('.basket-products-content').each(function(i,e){
			var checkbox = $(this).find('.toggle-basket-group-switch');
			if(checkbox.length){
				if(checkbox.is(':checked')){
					quotes_to_confirm.push($(this).data('quote_id'));
				}
			}
			else{
				//its not optional if no checkbox
				quotes_to_confirm.push($(this).data('quote_id'));
			}
		});

		
		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update
		};
		data['reset_confirmed_date'] = $(this).data('reset-confirmed-date');
		
		var api = new apiInterface();
		api.confirmOrders(data, function(response){
			if(response.success){
				hideLoader();
				window.location.href =  response.redirect_url;
			}
			else{
				hideLoader();
				if(isset(response.errrors)){
					response.errors.each(function(i,e){
						error_message(e);
					});
				}
				else{
					error_message('something went wrong');
				}
				hideLoader();
			}
		});
		
		return;
	});
});
